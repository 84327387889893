var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-card',{staticClass:"products-list",attrs:{"size":"xl"}},[_c('product-end',{staticClass:"mb-5"}),_c('div',{staticClass:"filter-row"},[_c('x-form-item',{staticClass:"field-composition_content_filter",attrs:{"name":"Поиск","label":"Поиск"}},[_c('x-input',{staticClass:"form-field-box_content_filter",attrs:{"placeholder":"Артикул WB/Артикул продавца/Наименование","prepend-icon":"search"},on:{"input":function($event){return _vm.$emit('search', $event || null)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('x-form-item',{attrs:{"name":"Категория","label":"Категория"}},[_c('x-select',{attrs:{"shadow":"","track-by":"id","label":"label","options":_vm.categories,"searchable":false,"allow-empty":false,"placeholder":"Категория"},on:{"select":_vm.changeCategory},model:{value:(_vm.selectCategory),callback:function ($$v) {_vm.selectCategory=$$v},expression:"selectCategory"}})],1)],1),(_vm.isLoading)?_c('div',{staticClass:"products-list__loader"},[_c('loader')],1):[_c('grid',{staticClass:"products-list__grid",attrs:{"auto":"","selectable":"","cells":_vm.list,"columns":_vm.columns1},on:{"cell-clicked":_vm.openMarketInfoModal},scopedSlots:_vm._u([{key:"created",fn:function(ref){
var created = ref.created;
return [_vm._v(" "+_vm._s(_vm.formatDate(created))+" ")]}},{key:"period",fn:function(ref){
var period = ref.period;
return [_vm._v(" "+_vm._s(period)+" ")]}},{key:"shopName",fn:function(ref){
var row = ref.row;
return [(row.marketplace_code)?_c('svg-icon',{staticClass:"mp-icon",attrs:{"name":row.marketplace_code}}):_vm._e(),_c('span',{staticClass:"marketplace__shop"},[_vm._v(" "+_vm._s(row.shop_name)+" ")])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('status',{staticClass:"products-list__badge",attrs:{"status":row.status,"icon":_vm.statusIcons[row.status],"status-list":_vm.statusList,"show-btn":_vm.isRecommendsBtnShown(row)}})]}}])}),(_vm.productsList.length)?_c('div',{staticClass:"products-list__pagination"},[_c('pagination',{attrs:{"data":_vm.pagination,"current-page":_vm.currentPage},on:{"change":_vm.onPaginationChange}})],1):_c('x-placeholder',{attrs:{"icon":"table-empty","desc":"Товары отсутствуют"}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }