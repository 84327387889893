<template>
  <x-card
    class="product-end"
    size="xl"
  >
    <div>
      <div v-if="showText">
        <div class="product-end__header">
          <svg-icon
            class="mr-1"
            name="report-problem"
            width="16"
            height="16"
          />
          <p>Товар скоро закончится:</p>
        </div>
        <p class="ml-6">
          — Умная колонка Капсула с Марусей от VK (MRC01LB).
          <a href="#" class="product-end__routing">Перейти</a>
        </p>
        <p class="ml-6">
          — Умная колонка Капсула с Марусей от VK (MRC01MRC01BL).
          <a href="#" class="product-end__routing">Перейти</a>
        </p>
        <p class="ml-6">
          — Умная колонка Капсула с Марусей от VK (MRC01MRC01WH).
          <a href="#" class="product-end__routing">Перейти</a>
        </p>
        <p class="ml-6">
          — Умная колонка Капсула с Марусей от VK (MRC02MRC02BK).
          <a href="#" class="product-end__routing">Перейти</a>
        </p>
        <p class="ml-6">
          — Умная колонка Капсула с Марусей от VK (MRC0456B).
          <a href="#" class="product-end__routing">Перейти</a>
        </p>
      </div>
    </div>
    <x-icon
      class="back-button"
      :name="showText ? 'dropdown-up' : 'dropdown-down'"
      size="s"
      @click="() => showText = !showText"
    />
  </x-card>
</template>

<script>
export default {
  name: 'ProductEnd',
  data () {
    return {
      showText: true
    }
  }
}
</script>

<style lang="stylus" scoped>
.product-end
  background-color #FFF4EC
  padding 8px
  display flex
  justify-content space-between

  &__header
    display flex
    align-items center

  &__routing
    text-decoration none
    color #5B40FF
</style>
