<template>
  <div class="export-list">
    <heading class="export-list__heading">
      Товары
    </heading>

    <products-list />
  </div>
</template>

<script>
import Heading from '@/components/Common/Heading.vue'
import ProductsList from '@/components/Dashboard/ProductsList.vue'

export default {
  components: {
    Heading,
    ProductsList
  }

}
</script>
