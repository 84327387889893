<template>
  <x-card
    class="products-list"
    size="xl"
  >

    <product-end class="mb-5" />

    <div class="filter-row">
      <x-form-item
        class="field-composition_content_filter"
        name="Поиск"
        label="Поиск"
      >
        <x-input
          v-model="search"
          placeholder="Артикул WB/Артикул продавца/Наименование"
          prepend-icon="search"
          class="form-field-box_content_filter"
          @input="$emit('search', $event || null)"
        />
      </x-form-item>
      <x-form-item
        name="Категория"
        label="Категория"
      >
        <x-select
          v-model="selectCategory"
          shadow
          track-by="id"
          label="label"
          :options="categories"
          :searchable="false"
          :allow-empty="false"
          placeholder="Категория"
          @select="changeCategory"
        />
      </x-form-item>
    </div>

    <div
      v-if="isLoading"
      class="products-list__loader"
    >
      <loader />
    </div>

    <template v-else>
      <grid
        auto
        selectable
        :cells="list"
        :columns="columns1"
        class="products-list__grid"
        @cell-clicked="openMarketInfoModal"
      >
        <template #created="{ created }">
          {{ formatDate(created) }}
        </template>

        <template #period="{ period }">
          {{ period }}
        </template>

        <template #shopName="{ row }">
          <svg-icon
            v-if="row.marketplace_code"
            :name="row.marketplace_code"
            class="mp-icon"
          />
          <span class="marketplace__shop">
            {{ row.shop_name }}
          </span>
        </template>

        <template #status="{ row }">
          <status
            :status="row.status"
            :icon="statusIcons[row.status]"
            :status-list="statusList"
            :show-btn="isRecommendsBtnShown(row)"
            class="products-list__badge"
          />
        </template>
      </grid>

      <div
        v-if="productsList.length"
        class="products-list__pagination"
      >
        <pagination
          :data="pagination"
          :current-page="currentPage"
          @change="onPaginationChange"
        />
      </div>

      <x-placeholder
        v-else
        icon="table-empty"
        desc="Товары отсутствуют"
      />
    </template>
  </x-card>
</template>

<script>
import Grid from '@/components/Interface/Grid.vue'
import Pagination from '@/components/Interface/Pagination.vue'
import Loader from '@/components/Common/Loader.vue'
import ProductEnd from '@/components/Dashboard/ProductEnd.vue'
import columns from '@/constants/exports'
import { productDashboardColumns as columns1 } from '@/constants/products'
import formatDate from '@/utils/date-formatter'
import { getMarketplaceIcon } from '@/utils/getMarketplaceIcon'
import PaginationMixin from '@/mixins/pagination'
import { createNamespacedHelpers } from 'vuex'
import toastNotification from '@/utils/toast-notification'
import Status from '@/components/Exports/Status.vue'

const { mapActions, mapState } = createNamespacedHelpers('exports')
const { mapGetters, mapActions: mapMarketplaceActions } = createNamespacedHelpers('marketplaces')
const { mapActions: mapTrackingActions } = createNamespacedHelpers('tracking')
const { mapActions: mapReportActions } = createNamespacedHelpers('reports')
const { mapState: mapProfileState } = createNamespacedHelpers('profile')
const { mapActions: mapDashboardActions } = createNamespacedHelpers('dashboards')

export default {
  mixins: [PaginationMixin],
  components: {
    Grid,
    Pagination,
    Loader,
    ProductEnd,
    Status
  },

  data () {
    return {
      columns,
      columns1,
      statusIcons: {
        finished: 'success-fill',
        failed: 'critical-error',
        failed_critical: 'critical-error',
        running: 'loader--gray',
        scheduled: 'clock'
      },
      statusList: {
        scheduled: 'В очереди',
        finished: 'Завершен',
        failed: 'Ошибка',
        running: 'В работе',
        failed_critical: 'Критическая ошибка'
      },
      isLoading: false,
      fetchIntervalId: null,
      errorMessage: '',
      recommendations: [],
      shopOptions: [],
      selectCategory: null,
      search: null,
      period: {
        start: '',
        end: ''
      },
      productsList: []
    }
  },

  computed: {
    // ...mapState(['exportsList']),
    ...mapProfileState(['user']),
    ...mapGetters(['filteredShopList']),
    ...mapMarketplaceActions(['getReportTypes']),

    categories () {
      const result = this.filteredShopList.map(shop => ({
        ...shop,
        label: shop.name + ' (' + shop.id + ')',
        $icon: getMarketplaceIcon(shop.marketplace_code)
      }))
      result.unshift({
        id: null,
        label: 'Все'
      })
      return result
    },

    isManager () {
      return this.user.service_model && this.user.service_model === 'xway-service'
    },

    list () {
      return this.productsList.map(el => ({
        ...el
      }))
    }
  },

  created () {
    this.fetchData()
    this.setExportsFetchInterval()
  },

  beforeDestroy () {
    clearInterval(this.fetchIntervalId)
  },

  methods: {
    ...mapActions(['getExports', 'getExportFile']),
    ...mapTrackingActions(['setEvent']),
    ...mapReportActions(['restartReport', 'stopReport']),

    formatDate (date) {
      return formatDate(date, 'DD MMMM YYYY г. HH:mm')
    },

    async fetchData (params, background = false) {
      try {
        if (!background) {
          this.isLoading = true
        }
        const payload = {
          additional_export_filters: {
            shop_id: this.selectCategory?.id,
            date_from: this.period.start,
            date_to: this.period.end
          },
          page: this.currentPage,
          limit: this.pagination.limit
        }
        const filters = {}

        for (const key in payload.additional_export_filters) {
          if (payload.additional_export_filters[key]) {
            filters[key] = payload.additional_export_filters[key]
          }
        }

        payload.additional_export_filters = filters

        const { data, pagination } = await this.getExports(payload)
        if (data) {
          this.pagination = { ...this.pagination, ...pagination }
          this.productsList = data
          this.isLoading = false
        }
      } catch (e) {
        toastNotification.error('Ошибка загрузки данных')
      }
    },

    setExportsFetchInterval () {
      this.fetchIntervalId = setInterval(() => {
        this.fetchData({}, true)
      }, 10000)
    },

    onPaginationChange ({ page, limit, offset }) {
      this.currentPage = page

      this.pagination.limit = limit

      this.fetchData({ page, limit, offset })
    },

    isRecommendsBtnShown (row) {
      return (row.status === 'failed' || row.status === 'failed_critical') &&
        !!row.recommendations && !!row.recommendations?.length
    },

    changeCategory (value) {
      this.selectCategory = value
      this.currentPage = 1
      this.search = null
      this.fetchData()
    }
  }
}
</script>

<style lang="stylus" scoped>
  .products-list

    &__loader
      text-align center

    &__grid
      margin 20px 0

      .grid__cell
        svg.mp-icon
          display inline-block
          vertical-align middle
          width 25px
          height 25px
          margin-right 5px

        span
          display inline-block
          vertical-align middle

    &__marketplace
      >>> .marketplace__desc
        display none

    &__badge
      >>> .svg-icon--loader--mini
        width 18px
        height 18px
        color #3cc13b

    .head-block
      display: flex
      margin-bottom: 20px

      .x-btn
        max-width: 200px
        margin-left: auto

    .filter-row
      .x-form-item
        width: 30%
        margin-right: 30px

  .badge
    display inline-flex
    align-items center
    padding 1px 6px
    border-radius 20px

    &__icon
      width 13px
      height 13px
      margin-right 5px

      &.svg-icon--pause
        fill #f9dd8d

  >>>.v-popover
    .trigger
      display flex !important
      align-items center
</style>
